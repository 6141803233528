export const environment = {
  production: false,
  staging: true,
  apiEndpoint: 'https://api.staging.services4eo.com/request',
  authority: 'https://triple-a.staging.services4eo.com',
  clientId: '87c37e45-e425-4ab7-9eb7-057b96005b9e',
  scope: 'profile openid email user_name gsc4eo',
  apiKey: 'd84c2e1e-uaxv-6314-usbz-886320696a82',
  support: 'https://service4eo.atlassian.net/servicedesk',
  storeUrl: 'https://store.staging.services4eo.com/'
};
